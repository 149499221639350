/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import ItemTags from "@minimal/components/item-tags"
import Ads from '@components/adsense'

type PostFooterProps = {
  post: {
    slug: string
    title: string
    date: string
    tags?: {
      name: string
      slug: string
    }[]
    description?: string
    canonicalUrl?: string
    body: string
    excerpt: string
    timeToRead?: number
    banner?: {
      childImageSharp: {
        resize: {
          src: string
        }
      }
    }
  }
}

const PostFooter = ({ post }: PostFooterProps) => {
	const path = (typeof window !== "undefined") ? location.pathname : "error: no path"
	
	return ( <React.Fragment>
		{post.tags && (
			<p sx={{ color: `secondary`, a: { color: `secondary` }, fontSize: [1, 1, 2] }}>
				{` — `}<ItemTags tags={post.tags} />{` — `}
			</p>
		)}
		<div sx={{ mt: 4 }}>
			<Ads path={path}></Ads>
		</div>
	</React.Fragment> )
}

export default PostFooter
