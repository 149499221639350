import React, { useEffect } from 'react';

export default function AdsCard({path}) {
	useEffect(() => {
		if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
			window.adsbygoogle.push({});
		}
	}, [path])

	//display-bottom
	return (
		<ins className="adsbygoogle"
			style={{ "display": "block" }}
			data-ad-client="ca-pub-5106375978729065"
			data-ad-slot="3405470619"
			data-ad-format="auto"
			data-full-width-responsive="true"></ins>
	);
}
