import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box } from "theme-ui"

const style = {
	CharaTable: props => (
		<Box sx={{
			"table": {
				"td": {
					px: [0, "4px", "4px"],
				},
			},
		}} {...props} />
	),
	TableList: props => (
		<Box sx={{
			"p": {
				mb: 0,
			},
			"table": {
				mt: 0,
				"td": {
					px: [0, "4px", "4px"],
				},
			},
		}} {...props} />
	),
	BuildList: props => (
		<Box sx={{
			"& > p": {
				fontWeight: `bold`,
				mb: 0,
			},
			"ul": {
				my: 1,
			},
			"table": {
				m: 0,
				"td": {
					px: "6px",
				},
			},
		}} {...props} />
	),
	TableScroll: props => (
		<Box sx={{
			overflow: "auto",
			my: 4,
			"table": {
				m: 0,
				"th, td": {
					whiteSpace: "nowrap",
				},
			},
		}} {...props} />
	),
}

const MyMdx = (props) => {
	return (
		<MDXProvider components={style}>
			<MDXRenderer>{props.children}</MDXRenderer>
		</MDXProvider>
	)
}

export default MyMdx
